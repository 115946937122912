import poolsConfig from 'config/constants/deblox/pools'
import { getAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import erc20ABI from 'config/abi/erc20.json'
import BigNumber from 'bignumber.js'
import { simpleRpcProvider } from 'utils/providers'
import debloxPoolsABI from 'config/abi/deblox/sousChef.json'

const nonBnbPools = poolsConfig
const nonMasterPools = poolsConfig

export const fetchFarmsAllowance = async (account) => {
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken ? pool.stakingToken.address : getAddress(pool.lpAddresses),
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls).catch((err) => console.error('--fetch allowance failed', err))
  // console.log('--DGS fetch allowance', allowances, nonBnbPools)
  return nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  // Non BNB pools
  const calls = nonBnbPools.map((pool) => ({
    address: (pool.stakingToken) ? pool.stakingToken.address : getAddress(pool.lpAddresses),
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(erc20ABI, calls)
  const tokenBalances = nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )

  return { ...tokenBalances }
}



export const fetchUserStakeBalances = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(debloxPoolsABI, calls)
  const stakedBalances = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  // Cake / Cake pool
  // const { amount: masterPoolAmount } = await masterChefContract.userInfo('0', account)

  return { ...stakedBalances }
}

export const fetchUserPendingRewards = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(debloxPoolsABI, calls)
  const pendingRewards = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  // Cake / Cake pool
  // const pendingReward = await masterChefContract.pendingCake('0', account)

  return { ...pendingRewards }
}
