import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const HOST_URL = process.env.REACT_APP_GAME_SITE === 'true' ? 'https://deblox.io' : ''
const GAME_URL = process.env.REACT_APP_GAME_SITE === 'true' ? '' : 'https://games.deblox.io'
// console.log('----menu', HOST_URL, process.env.REACT_APP_CHAIN_ID)
const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = 
process.env.REACT_APP_CHAIN_ID === '56' ? 
(t) => [
  {
    label: t('Avatar'),
    icon: 'Predictions',
    href: `${HOST_URL}/avatar`,
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('Game'),
    icon: 'TeamBattle',
    href: `${GAME_URL}/game`,
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('Farm'),
    icon: 'Farm',
    href: `${HOST_URL}/farm`,
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('Community'),
    icon: 'Groups',
    href: `${HOST_URL}/community`,
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: '',
    href: '#',
    icon: 'More',
    hideSubNav: true,
    items: [
      {
        label: t('Lite Paper'),
        href: 'https://static.deblox.io/files/deblox-litepaper-v1.0.pdf',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Docs'),
        href: 'https://docs.deblox.io',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      {
        label: t('About Us'),
        href: 'https://docs.deblox.io',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
]
:
(t) => [
  // {
  //   label: t('IGO'),
  //   icon: 'Vote',
  //   href: '/igo',
  //   hideSubNav: true,
  //   showItemsOnMobile: true,
  //   items: [
  //     {
  //       label: t('Introduction'),
  //       href: '/igo',
  //     },
  //     {
  //       label: t('User'),
  //       href: '/user',
  //     },
  //     {
  //       label: t('Provider'),
  //       href: '/provider',
  //     },
  //   ],
  // },
  {
    label: t('Avatar'),
    icon: 'Predictions',
    href: '/avatar',
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('Game'),
    icon: 'TeamBattle',
    href: '/game',
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('Farm'),
    icon: 'Farm',
    href: '/farm',
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('Community'),
    icon: 'Groups',
    href: '/community',
    hideSubNav: true,
    showItemsOnMobile: false,
    items: []
  },
  {
    label: '',
    href: '#',
    icon: 'More',
    hideSubNav: true,
    items: [
      {
        label: t('Lite Paper'),
        href: 'https://static.deblox.io/files/deblox-litepaper-v1.0.pdf',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Docs'),
        href: 'https://docs.deblox.io',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      {
        label: t('Community'),
        href: 'https://docs.deblox.io/services/communities',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('About Us'),
        href: 'https://docs.deblox.io',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
]

export default config
