import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import useUserAgent from 'hooks/useUserAgent'
import useScrollOnRouteChange from 'hooks/useScrollOnRouteChange'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { usePollProviderNFTCollections, useUserAvatarData } from 'state/deblox/igo/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { usePollGameSlotData } from 'state/deblox/slots/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import GlobalCheckClaimStatus from './components/GlobalCheckClaimStatus'
import ToTelegramModal from './components/ToTelegramModal'

import history from './routerHistory'
// Views included in the main bundle

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Intro = lazy(() => import('./views/Intro'))
// const Entrance = lazy(() => import('./views/Entrance'))
const User = lazy(() => import('./views/User'))
const Avatar = lazy(() => import('./views/Avatar'))
const Game = lazy(() => import('./views/Game'))
const GameDetail = lazy(() => import('./views/GameDetail'))
const GameOwner = lazy(() => import('./views/GameOwner'))

const Farm = lazy(() => import('./views/Farm'))
const Community = lazy(() => import('./views/Community'))

// const FarmAuction = lazy(() => import('./views/FarmAuction'))

const Forbidden = lazy(() => import('./views/Forbidden'))
const NotFound = lazy(() => import('./views/NotFound'))

// const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
// const Liquidity = lazy(() => import('./views/Pool'))
// const PoolFinder = lazy(() => import('./views/PoolFinder'))
// const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))

// const ProfileCreation = lazy(() => import('./views/ProfileCreation'))
// const AvatarCreation = lazy(() => import('./views/AvatarCreation'))
const Providers = lazy(() => import('./views/Providers'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account } = useWeb3React()

  usePollBlockNumber()
  useEagerConnect()
  // useFetchProfile()
  usePollCoreFarmData()
  useScrollOnRouteChange()
  useUserAgent()
  
  usePollProviderNFTCollections()
  // usePollGamesPublicData()

  usePollGameSlotData()
  useUserAvatarData()
  
  const gameSite = process.env.REACT_APP_GAME_SITE === 'true'
  const mainnet = process.env.REACT_APP_CHAIN_ID === '56'

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={[]} />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/igo" exact>
              <Intro />
            </Route>
            <Route path="/provider" exact>
              <Providers />
            </Route>
            <Route path="/user" exact>
              <User />
            </Route>
            <Route path="/avatar" exact>
              <Avatar />
            </Route>
            <Route path="/farm">
              <Farm />
            </Route>
            <Route path="/community">
              <Community />
            </Route>
            <Route exact strict path="/game-owner/:tokenId" component={GameOwner} />

            {/* If mainnet, only accessiable via https://games.deblox.io */}
            {mainnet === true
            ?
            (
              gameSite === true && 
              (
                <>
                <Route path="/game" exact>
                  <Game />
                </Route>
                <Route exact strict path="/game/:tokenId" component={GameDetail} />
                </>
              )
            )
            :
            (<>
              <Route path="/game" exact>
                <Game />
              </Route>
              <Route exact strict path="/game/:tokenId" component={GameDetail} />
              </>
            )
            }
            {/* <Route path="/game-detail" exact>
              <GameDetail />
            </Route> */}
            {/* <Route exact path="/farms/auction">
              <FarmAuction />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/create-profile">
              <ProfileCreation />
            </Route>
            <Route path="/create-avatar">
              <AvatarCreation />
            </Route>
            <Route path="/create-game">
              <Providers />
            </Route> */}

            {/* <Route path="/games">
              <Games />
            </Route> */}

            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            {/* <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/quick-buy" component={QuickBuy} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}

            {/* Redirect */}
            <Route path="/igo">
              <Redirect to="/intro" />
            </Route>
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/collectibles">
              <Redirect to="/nfts" />
            </Route>
            <Route path="/profile">
              <Redirect to={`${nftsBaseUrl}/profile/${account?.toLowerCase() || ''}`} />
            </Route>

            {/* 403 */}
            <Route path="/403" component={Forbidden} />
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
      <ToTelegramModal />
    </Router>
  )
}

export default React.memo(App)
