import { serializeTokens } from '../tokens'
import { SerializedGameConfig } from '../types'

const serializedTokens = serializeTokens()

const games: SerializedGameConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    sid: 0,
    contractAddress: {
      97: '0x14B1Dc664B1251894817e2bE1a98C0B196D719C3',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    gameProvider: {
      97: '0x764dA9a319A9E60d7E123CF17cFC747Aa0be49De',
      56: '0xc1cFe875A5be3e110BFE6e993dAda48c57AA77B2',
    },
    stakingToken: serializedTokens.dgs,
    rewardsToken: serializedTokens.dgsg,
    tokenPerBlock: '0.01',
    sortOrder: 1,
    isFinished: false,
    initialized: true
  },
  // {
  //   sid: 1,
  //   contractAddress: {
  //     97: '0x8Ac472cfc536a9931923FB9C9Ab688278a87B250',
  //     56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  //   },
  //   gameProvider: {
  //     97: '0x764dA9a319A9E60d7E123CF17cFC747Aa0be49De',
  //     56: '0xc1cFe875A5be3e110BFE6e993dAda48c57AA77B2',
  //   },
  //   stakingToken: serializedTokens.dgs,
  //   rewardsToken: serializedTokens.dgsg,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
  {

    sid: 10,
    contractAddress: {
      97: '0xcb8C41DD44dd5Ca56B403A497ee47486CC83026B',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    gameProvider: {
      97: '0x764dA9a319A9E60d7E123CF17cFC747Aa0be49De',
      56: '0xc1cFe875A5be3e110BFE6e993dAda48c57AA77B2',
    },
    stakingToken: serializedTokens.dgs,
    rewardsToken: serializedTokens.dgsg,
    tokenPerBlock: '100',
    sortOrder: 1,
    isFinished: false,
    initialized: true
  },
]

export default games
