import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import slots from 'config/constants/deblox/slots'
import { AppThunk } from 'state/types'
import { getAddress } from 'utils/addressHelpers'
import { fetchGameSlotContract } from './helpers'
import { GameSlotsState, IgoNftInitializationState, SlotContractState } from './types'

const initialState: GameSlotsState = {
  data: []
}

// Thunks
export const fetchGameSlotDetails = createAsyncThunk<SlotContractState[]>('slot/fetchGameSlotDetails', async () => {

  const liveData = await Promise.all(
    slots.map((slot) => {
      const address = getAddress(slot.address)
      if (address.length === 0) {
        return {} as SlotContractState
      }
      return fetchGameSlotContract(address)
    })
  ) 

  // console.log('---liveData', liveData)
  return liveData
  // dispatch(setPoolsPublicData(liveData))
})

export const SlotsSlice = createSlice({
  name: 'Slot',
  initialState,
  reducers: {
  },

  
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchGameSlotDetails.fulfilled, (state, action) => {
      // console.log('===index farms builder', action.payload)
      state.data = action.payload
    })
  },
})

// Actions
// export const { setPoolsPublicData } = SlotsSlice.actions

export default SlotsSlice.reducer
