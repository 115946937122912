import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Button } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import useParsedQueryString from 'hooks/useParsedQueryString'

const Wrapper = styled(Box)`
  position: fixed;
  right: 0;
  background: #333f5b;
  padding: 24px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  bottom: 49px;
  width: 100%;
  z-index: 99;
  &.hide {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    border-radius: 10px 0 0 0;
    bottom: 0;
    width: 300px;
  }
  .img-wrap{
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    padding: 8px 6px 6px;
  }
  .close-btn {
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
`

const DISCORD_DEBLOX_IO_INVITE = process.env.DISCORD_DEBLOX_IO_INVITE || "https://discord.gg/GcVu3Ekkmd"

const ToTelegramModal = () => {
  const { t } = useTranslation()
  const [num, setNum] = useState(3)
  const [show, setShow] = useState(false)
  
  const parsedQs = useParsedQueryString()
  const campaignId = (typeof parsedQs.utm_id === 'string') ?  parsedQs.utm_id : ''

  let timer = null
  function ToTelegram() {
    window.open(`https://discord.gg/GcVu3Ekkmd`)
    localStorage.setItem('toTelegram', 'true')
  }

  useEffect(() => {
    function clearTimer() {
      if (timer) {
        clearTimeout(timer)
      // eslint-disable-next-line
        timer = null
      }
    }
    if (localStorage.getItem('toTelegram')) {
      return () => {
        console.log('Notified already')
      }
    }
    clearTimer()
    if (num === 3) {
      // eslint-disable-next-line
      timer = setTimeout(() => {
          setShow(true)
          setNum(num - 1)
          localStorage.setItem('toTelegram', 'true')
          clearTimer()
      }, 10000)
    } else {
      // eslint-disable-next-line
      timer = setTimeout(() => {
          setNum(num - 1)
          clearTimer()
      }, 1000)
    }
    return () => {
      clearTimer()
    }
  }, [num, show])
  return (
    <Wrapper className={show ? 'show' : 'hide'}>
      <Box className="close-btn" onClick={ () => setShow(false) }>x</Box>
      <Box className="img-wrap">
        <img src="/images/community/discord.png" alt="" />
      </Box>
      <Box m="12px 0">{t('Hi, there')}</Box>
      <Box>
        {t('Join Our Telegram')}
      </Box>
      <Box m="24px 0 12px"><Button scale='sm' onClick={() => ToTelegram() }> {t('Join Now')} </Button></Box>
    </Wrapper>
  )
}

export default ToTelegramModal
