import { BigNumberish } from 'ethers'


export enum IgoNftInitializationState {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZING = 'INITIALIZING',
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
}

export interface AvatarRarity {
  [Component: string]: {
    trait: string
    weight: number
    occurrence: string
  }[]
}

export enum NftFilterLoadingState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

export interface UserNftsState {
  userNftsInitializationState: IgoNftInitializationState
  nfts: TokenIdWithCollectionAddress
}

/**
 * Stored the data per account
 */
export interface TokenIdWithCollectionAddress {
  collectionAddress: string
  tokenIds: string[]
  isWhitelisted: boolean
}

export interface ContractState {
  saleLive: boolean
  price: string
  addressLimit: number
  soldout: boolean
  currentId: number
  maxSupply: number
  onlyWhitelisted: boolean
  maxMintAmount: number
}

export interface AirdropContractState {
  claimable: boolean
  balance: number
  claimableTokenIds: number[]
}

export interface TokenAirdropContractState {
  claimable: boolean
  balance: string
  hasClaimed: boolean
  isWhitelisted: boolean
  isPremium: boolean
  claimableAmount: string
  claimablePremiumAmount: string
}

export interface IgoNftsState {
  initializationState: IgoNftInitializationState
  data: {
    avatarContract: ContractState
    providerContract: ContractState
    avatar: UserNftsState
    provider: UserNftsState
    ownerId: string
  }
  avatarRarity: any
  claimsRecords: AirdropContractState
  tokenClaimsRecords: TokenAirdropContractState
}

export interface NFTCollections {
  [key: string]: Collection
}

export interface Transaction {
  id: string
  block: string
  timestamp: string
  askPrice: string
  netPrice: string
  buyer: { id: string }
  seller: { id: string }
  withBNB: boolean
  nft?: TokenMarketData
}

export interface Image {
  original: string
  thumbnail: string
  mp4?: string
  webm?: string
  gif?: string
}

// Market data regarding specific token ID, acquired via subgraph
export interface TokenMarketData {
  tokenId: string
  metadataUrl: string
  currentAskPrice: string
  currentSeller: string
  latestTradedPriceInBNB: string
  tradeVolumeBNB: string
  totalTrades: string
  isTradable: boolean
  otherId: string
  collection?: {
    id: string
  }
  updatedAt?: string
  transactionHistory?: Transaction[]
}

// Represents single NFT token, either Squad-like NFT or single PancakeBunny.
export interface NftToken {
  tokenId: string
  name: string
  description: string
  collectionName: string
  collectionAddress: string
  image: Image
  attributes?: NftAttribute[]
  createdAt?: string // API createdAt
  updatedAt?: string // API updatedAt
  marketData?: TokenMarketData
  meta?: Record<string, string | number>
}

export interface NftAttribute {
  traitType: string
  value: string | number
  displayType: string
}

// Internal type used to refer to a collection
// Most fields are populated from API (via ApiCollection type)
export interface Collection {
  id: string
  address: string
  name: string
  description?: string
  symbol: string
  active: boolean
  totalVolumeBNB: string
  numberTokensListed: string
  tradingFee: string
  creatorFee: string
  owner: string
  totalSupply: string
  verified: boolean
  avatar: string
  banner: {
    large: string
    small: string
  }
  attributes?: NftAttribute[]
}

export interface User {
  address: string
  numberTokensListed: BigNumberish
  numberTokensPurchased: BigNumberish
  numberTokensSold: BigNumberish
  nfts: Record<string, BigNumberish> // String is an address, BigNumberish is a tokenID
}

/**
 * SUBGRAPH RESPONSES
 */

export interface CollectionMarketDataBaseFields {
  id: string
  name: string
  symbol: string
  active: boolean
  totalTrades: string
  totalVolumeBNB: string
  numberTokensListed: string
  creatorAddress: string
  tradingFee: string
  creatorFee: string
  whitelistChecked: string
}
