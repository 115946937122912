import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall'
import poolsConfig from 'config/constants/deblox/pools'
import { getAddress } from 'utils/addressHelpers'
import tokens from 'config/constants/tokens'
import debloxPoolsABI from 'config/abi/deblox/sousChef.json'
import dgsABI from 'config/abi/deblox/cake.json'
import { ERC20_ABI } from 'config/abi/erc20'

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig // .filter((p) => p.sousId !== 0)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    // console.log('---address', getAddress(poolConfig.contractAddress))
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndBlock',
    }
  })

  const starts = await multicall(debloxPoolsABI, callsStartBlock)
  const ends = await multicall(debloxPoolsABI, callsEndBlock)

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    // console.log('block limit', new BigNumber(startBlock).toJSON(), new BigNumber(endBlock).toJSON())
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchPoolsTotalStaking = async () => {
  const callsNonBnbPools = poolsConfig.map((pool) => {
    return {
      address: pool.stakingToken ? pool.stakingToken.address : getAddress(pool.lpAddresses),
      name: 'balanceOf',
      params: [getAddress(pool.contractAddress)],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(ERC20_ABI, callsNonBnbPools)

  return [
    ...poolsConfig.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}
