import React from 'react'
import { Flex, Text, Spinner } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

interface ApproveAndConfirmStageProps {
  variant: 'buy' | 'sell'
  isApproved: boolean
  isApproving: boolean
  isConfirming: boolean
}

// Buy Flow:
// Shown if user wants to pay with WBNB and contract isn't approved yet
// Sell Flow:
// Shown the first time user puts NFT for sale
const ApproveAndConfirmStage: React.FC<ApproveAndConfirmStageProps> = ({
  variant,
  isApproved,
  isApproving,
  isConfirming,
}) => {
  const { t } = useTranslation()

  return (
    <Flex p="16px" flexDirection="column">
      <Flex mb="8px" alignItems="center">
        <Flex flex="0 0 64px" width="64px">
          {isApproving && <Spinner size={64} />}
        </Flex>
      </Flex>
      <Flex alignItems="center" mt="8px">
        <Flex flexDirection="column">
          <Flex alignItems="center" mt="16px">
            <Text fontSize="20px" bold color={isApproved ? 'secondary' : 'textDisabled'}>
              {t('Confirm')}
            </Text>
          </Flex>
          <Text small color={isApproved ? 'textSubtle' : 'textDisabled'}>
            {t('Please confirm the transaction in your wallet')}
          </Text>
        </Flex>
        <Flex flex="0 0 64px" width="64px">
          {isConfirming && <Spinner size={64} />}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ApproveAndConfirmStage
