import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchOwner, fetchRarity, fetchWalletTokenIdsForCollection, getAirdropContract, getInfoPerNftContract, getTokenAirdropContract } from './helpers'
import { AirdropContractState, AvatarRarity, ContractState, IgoNftInitializationState, IgoNftsState, TokenAirdropContractState, TokenIdWithCollectionAddress } from './types'

const initialState: IgoNftsState = {
  initializationState: IgoNftInitializationState.UNINITIALIZED,
  data: { 
    avatarContract: {
      saleLive: false,
      price: '0.0',
      addressLimit: 0,
      soldout: false,
      currentId: 0,
      maxSupply: 0,
      onlyWhitelisted: false,
      maxMintAmount: 0
    },
    providerContract: {
      saleLive: false,
      price: '0.0',
      addressLimit: 0,
      soldout: false,
      currentId: 0,
      maxSupply: 0,
      onlyWhitelisted: false,
      maxMintAmount: 0
    },
    avatar: {
      userNftsInitializationState: IgoNftInitializationState.UNINITIALIZED,
      nfts: {
        tokenIds: [],
        collectionAddress: '',
        isWhitelisted: false
      },
    },
    provider: {
      userNftsInitializationState: IgoNftInitializationState.UNINITIALIZED,
      nfts: {
        tokenIds: [], 
        collectionAddress: '',
        isWhitelisted: false
      },
    },
    ownerId: ''
  },
  avatarRarity: {},
  claimsRecords: {
    claimable: false,
    balance: 0,
    claimableTokenIds: []
  },
  tokenClaimsRecords: {
    claimable: false,
    balance: '0',
    hasClaimed: false,
    isWhitelisted: false,
    isPremium: false,
    claimableAmount: '0',
    claimablePremiumAmount: '0'
  }
}

export const fetchNFTOwnerByTokenId = createAsyncThunk<
  string,
  {collectionAddress: string, inputId: number}
>('nft/fetchNFTOwnerByTokenId', async ({collectionAddress, inputId}) => {
  return fetchOwner(collectionAddress, inputId)
})

export const fetchAvatarRarity = createAsyncThunk<AvatarRarity[]>('nft/fetchAvatarRarity', async () => {
  return fetchRarity()
})

export const fetchAirdropContract = createAsyncThunk<
  AirdropContractState,
  { tokenIds: number[], airdropAddress: string }
>('igo/fetchAirdropContract', async ({ tokenIds, airdropAddress }) => {
  const contractData = await getAirdropContract(tokenIds, airdropAddress)
  return contractData
})

export const fetchTokenAirdropContract = createAsyncThunk<
  TokenAirdropContractState,
  { account: string, airdropAddress: string }
>('igo/fetchTokenAirdropContract', async ({ account, airdropAddress }) => {
  const contractData = await getTokenAirdropContract(account, airdropAddress)
  return contractData
})

export const fetchProviderContract = createAsyncThunk<
  ContractState,
  { collectionAddress: string }
>('igo/fetchProviderContract', async ({ collectionAddress }) => {
  const contractData = await getInfoPerNftContract(collectionAddress)
  return contractData
})

export const fetchAvartarContract = createAsyncThunk<
  ContractState,
  { collectionAddress: string }
>('igo/fetchAvartarContract', async ({ collectionAddress }) => {
  const contractData = await getInfoPerNftContract(collectionAddress)
  return contractData
})

export const fetchAvatarNfts = createAsyncThunk<
  TokenIdWithCollectionAddress,
  { account: string; collectionAddress: string }
>('igo/fetchAvatarNfts', async ({ account, collectionAddress }) => {
  const nftData = await fetchWalletTokenIdsForCollection(account, collectionAddress)
  return nftData
})

export const fetchProviderNfts = createAsyncThunk<
  TokenIdWithCollectionAddress,
  { account: string; collectionAddress: string }
>('igo/fetchProviderNfts', async ({ account, collectionAddress }) => {
  const nftData = await fetchWalletTokenIdsForCollection(account, collectionAddress)
  return nftData
})

export const DebloxNFT = createSlice({
  name: 'IgoNFT',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder.addCase(fetchNFTOwnerByTokenId.rejected, (state, action) => {
      console.error('-----fetchNFTOwnerByTokenId error', action.error)
    })
    builder.addCase(fetchNFTOwnerByTokenId.pending, (state) => {
      state.initializationState = IgoNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchNFTOwnerByTokenId.fulfilled, (state, action) => {
      console.log('------fetchNFTOwnerByTokenId', action.payload)
      state.data.ownerId = action.payload
    })

    builder.addCase(fetchAirdropContract.rejected, (state, action) => {
      console.error('-----fetchAirdropContract error', action.error)
    })
    builder.addCase(fetchAirdropContract.pending, (state) => {
      state.initializationState = IgoNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchAirdropContract.fulfilled, (state, action) => {
      // console.log('------fetchAvatarRarity', action.payload)
      state.claimsRecords = action.payload
    })

    builder.addCase(fetchTokenAirdropContract.rejected, (state, action) => {
      console.error('-----fetchTokenAirdropContract error', action.error)
    })
    builder.addCase(fetchTokenAirdropContract.pending, (state) => {
      state.initializationState = IgoNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchTokenAirdropContract.fulfilled, (state, action) => {
      // console.log('------fetchTokenAirdropContract', action.payload)
      state.tokenClaimsRecords = action.payload
    })

    builder.addCase(fetchAvatarRarity.rejected, (state, action) => {
      console.error('-----fetchAvatarRarity error', action.error)
    })
    builder.addCase(fetchAvatarRarity.fulfilled, (state, action) => {
      // console.log('------fetchAvatarRarity', action.payload)
      state.avatarRarity = action.payload
    })

    builder.addCase(fetchAvartarContract.rejected, (state, action) => {
      console.error('-----fetchAvartarContract error', action.error)
      state.initializationState = IgoNftInitializationState.ERROR
    })
    builder.addCase(fetchAvartarContract.pending, (state) => {
      state.initializationState = IgoNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchAvartarContract.fulfilled, (state, action) => {
      state.data.avatarContract = action.payload
      state.initializationState = IgoNftInitializationState.INITIALIZED
    })

    builder.addCase(fetchProviderContract.rejected, (state, action) => {
      console.error('-----fetchProviderContract error', action.error)
      state.initializationState = IgoNftInitializationState.ERROR
    })
    builder.addCase(fetchProviderContract.pending, (state) => {
      state.initializationState = IgoNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchProviderContract.fulfilled, (state, action) => {
      // console.log('-----fetchProviderContract fulfilled', action.payload)
      state.data.providerContract = action.payload
      state.initializationState = IgoNftInitializationState.INITIALIZED
    })

    builder.addCase(fetchAvatarNfts.rejected, (state, action) => {
      console.error('-----fetchAvatarNfts error', action.error)
      state.data.avatar.userNftsInitializationState = IgoNftInitializationState.ERROR
    })
    builder.addCase(fetchAvatarNfts.pending, (state) => {
      state.data.avatar.userNftsInitializationState = IgoNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchAvatarNfts.fulfilled, (state, action) => {
      // console.log('-----fetchAvatarNfts fulfilled', action.payload)
      state.data.avatar.nfts = action.payload
      state.data.avatar.userNftsInitializationState = IgoNftInitializationState.INITIALIZED
    })

    builder.addCase(fetchProviderNfts.rejected, (state, action) => {
      console.error('-----fetchProviderNfts error', action.error)
      state.data.provider.userNftsInitializationState = IgoNftInitializationState.ERROR
    })
    builder.addCase(fetchProviderNfts.pending, (state) => {
      state.data.provider.userNftsInitializationState = IgoNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchProviderNfts.fulfilled, (state, action) => {
      // console.log('-----fetchProviderNfts fulfilled', action.payload)
      state.data.provider.nfts = action.payload
      state.data.provider.userNftsInitializationState = IgoNftInitializationState.INITIALIZED
    })
  },
})

export default DebloxNFT.reducer
