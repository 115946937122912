import { SlotConfigProps } from "state/deblox/types"

const slots: SlotConfigProps[] = [
  {
    id: 1,
    address: {
      97: "0x441Ff738A17B7d306A91Bf521916B06806ef7A1b",
      56: "0xDC5Bf36581f19BCc35F7c1C21CB6c267eD93F291"
    }
  }, 
  {
    id: 2,
    address: {
      97: "0x7A2a6276390b4e22dCaF14D9a32E12477b69b051",
      56: "0x21Cc613306cca6a884FABd358eABe5eD536ecA1E"
    }
  }, 
  {
    id: 3,
    address: {
      97: "0x7F7f30909A38845C3D71B31CEBd11332DD60FCDa",
      56: "0x8f734fE77EFa3F49CAF3C99Ea161aF1702e2e3dC"
    }
  }, 
  {
    id: 4,
    address: {
      97: "0xEcE024efDD7bd479eF7F3a23Bb321532a5e1DD49",
      56: ""
    }
  }, 
  {
    id: 5,
    address: {
      97: "0xdeccefd6d8b5C421C301F798Dc427170269eba40",
      56: ""
    }
  }
]

export default slots
