import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, Box, Flex, Link, MetamaskIcon, Button, LinkExternal } from '@pancakeswap/uikit'
import { useTranslation } from "contexts/Localization"
import { registerToken } from 'utils/wallet'
import { serializeTokens } from 'config/constants/tokens'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useWeb3React } from '@web3-react/core'
import { BuyingStage } from 'views/Avatar/components/BuyModal/types'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { useAppDispatch } from 'state'
import { useTokenAirdropContractState } from 'state/deblox/igo/hooks'
import { getTokenAirdropContractAddress } from 'utils/addressHelpers'
import { useTokenAirdropContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import useApproveConfirmTransaction from 'hooks/useApproveConfirmTransaction'
import { fetchTokenAirdropContract } from 'state/deblox/igo/reducer'
import { ToastDescriptionWithTx } from 'components/Toast'
import ApproveAndConfirmStage from 'views/Avatar/components/ClaimModal/ApproveAndConfirmStage'

const StyledModal = styled(Modal)`
width: 700px;
max-width: 90%;
font-size: 14px;
line-height: 20px;
${({ theme }) => theme.mediaQueries.lg} {
  font-size: 20px;
}
h2 {
  width: 100%;
  text-align: center;
}
& > div:last-child {
  padding: 0;
}

.certik {
  position: absolute;
  right: 4%;
  bottom: 38%;
  img {
    width: 88px;
  }
}

.modal-body {
  padding: 2% 5%;
  text-align: center;
  position: relative;
  .title {
    line-height: 78px;
    font-size: 28px;
    font-weight: 600;
  }
  .desc {
    font-size: 18px;
    color: #757575;
    flex: 0 0 50%;
    margin-left: 24px;
  }
}
`
const BorderBox = styled(Button)`
  border-radius: 4px;
  padding: 2px;
  height: 24px;
  & > div {
    border-radius: 4px;
    font-weight: 600;
    padding: 0 6px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
  }
`

const ClaimModalBody = ({ onDismiss }) => {
  const [stage, setStage] = useState(BuyingStage.REVIEW)
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()

  const dispatch = useAppDispatch()
  const airdropContract = useTokenAirdropContract()
  const airdropContractInfo = useTokenAirdropContractState()
  const airdropAddress = getTokenAirdropContractAddress()

  const { toastSuccess } = useToast()
  
  const { isApproving, isApproved, isConfirming, handleConfirm } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      return false
    },
    onApprove: () => {
      return null
    },
    onApproveSuccess: async () => {
      return null
    },
    onConfirm: () => {
      // console.log('---batchClaim', airdropContract)
      return callWithGasPrice(airdropContract, 'claim')
    },
    onSuccess: async ({ receipt }) => {
      setStage(BuyingStage.TX_CONFIRMED)
      dispatch(fetchTokenAirdropContract({ account, airdropAddress }))
      toastSuccess(
        t('Claimed successfully'),
        <ToastDescriptionWithTx txHash={receipt.transactionHash} />,
      )
    },
  })

  const claimableAmount = airdropContractInfo.isPremium ? airdropContractInfo.claimablePremiumAmount : airdropContractInfo.claimableAmount
  
  const serializedTokens = serializeTokens()
  
  return (
    <>
    {stage === BuyingStage.REVIEW && (
      (airdropContractInfo.claimable && airdropContractInfo.isWhitelisted && !airdropContractInfo.hasClaimed) 
      ?
      (
      <Box className="modal-body">
        <Box className="title">{t('You have %num% %token% pending to claim', {num: claimableAmount, token: "DGS-G"})}</Box>
        <Flex p="6% 0 8%" justifyContent="center" alignItems="center">
          <Flex className="icon-wrap" alignItems="center">
            <img src="/images/farm/dgsg_logo.png" alt="" style={{width: '58px', height: '61px' }} />
            <BorderBox className="meta-btn" onClick={() => registerToken(serializedTokens.dgsg.address, serializedTokens.dgsg.symbol, serializedTokens.dgsg.decimals)}>
              + <MetamaskIcon m="-3px 0 0 -3px" width="16px" style={{ verticalAlign: 'middle' }}/>
            </BorderBox>
          </Flex>
          <Box className='desc'>{t('Click Metamask logo to add %token% contract into your wallet', {token: "DGS-G"})}</Box>
        </Flex>
        <Flex justifyContent="center" pb="8%">
          <Box style={{width: '80%'}}>
            <Button
              style={{width: '100%'}}
              disabled={claimableAmount === '0' || isConfirming}
              isLoading={isConfirming}
              onClick={handleConfirm}
            >
              {isConfirming ? `${t('Confirming')}...` : t('Claim')}
            </Button>
          </Box>
        </Flex>
        <Link className="certik" href="https://www.certik.com/projects/deblox" target="_blank">
          <img src="/images/avatar/certik.png" alt=""/>
        </Link>
      </Box>
      )
      :
      (
        <>
        <Box className="modal-body">
          
          <Flex p="8% 0 16%" justifyContent="center">
            <Box className="title">
              {t('Join Our Telegram')}
              <LinkExternal href="https://discord.gg/wMAAAdQFau">Deblox Discord</LinkExternal>
            </Box>
          </Flex>
          <Flex justifyContent="center" pb="8%">
            <Box style={{width: '80%'}}>
              <Button style={{width: '100%'}} disabled>
                {airdropContractInfo.claimable ?
                (airdropContractInfo.isWhitelisted ? t('Claimed') : t('Your account is not eligible'))
                :
                (t('Airdrop is not started'))
                }
              </Button>
            </Box>
          </Flex>
          <Link className="certik" href="https://www.certik.com/projects/deblox" target="_blank">
            <img src="/images/avatar/certik.png" alt=""/>
          </Link>
        </Box>
      </>
      )
    )}
    {stage === BuyingStage.APPROVE_AND_CONFIRM && (
        <ApproveAndConfirmStage
          variant="buy"
          isApproved={isApproved}
          isApproving={isApproving}
          isConfirming={isConfirming}
        />
      )}
    {stage === BuyingStage.TX_CONFIRMED && (
      <Box className="modal-body">
        <Flex p="8% 0 16%" justifyContent="center">
          <Box className="title">{t('You have claimed airdrop successfully')}</Box>
        </Flex>
        <Flex justifyContent="center" pb="8%">
          <Box style={{width: '80%'}}><Button style={{width: '100%'}} onClick={() => onDismiss()}>{t('Close')}</Button></Box>
        </Flex>
        <Link className="certik" href="https://www.certik.com/projects/deblox" target="_blank">
          <img src="/images/avatar/certik.png" alt=""/>
        </Link>
      </Box>
    )}
    </>
  )
}

// const SuccessModalBody = ({onDismiss}) => {
//   return (
//     <Box className="modal-body">
//       <Flex p="8% 0 16%" justifyContent="center">
//         <Box className="title">You have claimed airdrop successfully</Box>
//       </Flex>
//       <Flex justifyContent="center" pb="8%">
//         <Box style={{width: '80%'}}><Button style={{width: '100%'}} onClick={() => onDismiss()}>Close</Button></Box>
//       </Flex>
//       <Link className="certik" href="https://www.certik.com/projects/deblox" target="_blank">
//         <img src="/images/avatar/certik.png" alt=""/>
//       </Link>
//     </Box>
//   )
// }

const ConnectModalBody = () => {
  const { t } = useTranslation()

  return (
    <Box className="modal-body">
      <Flex p="8% 0 16%" justifyContent="center">
        <Box className="title">{t('Please connect your wallet to continue')}</Box>
      </Flex>
      <Flex justifyContent="center" pb="8%">
        <Box style={{width: '80%'}}><ConnectWalletButton style={{width: '100%'}} /></Box>
      </Flex>
      <Link className="certik" href="https://www.certik.com/projects/deblox" target="_blank">
        <img src="/images/avatar/certik.png" alt=""/>
      </Link>
    </Box>
  )
}

const AirportModal = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <StyledModal
      title={t('Claim %token% Airdrop', {token: 'DGS-G'})}
      onDismiss={onDismiss}
      headerBackground="#727173"
      bodyPadding="0"
    >
      {account 
      ?
        <ClaimModalBody onDismiss={onDismiss} />
      :
        <ConnectModalBody />
      }
      {/*  */}
      {/* <SuccessModalBody onDismiss={onDismiss} /> */}
      
    </StyledModal>
  )
}
export default AirportModal
