import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import { useLocation } from 'react-router'
import { Menu as UikitMenu, Button, useModal, Box, useMatchBreakpoints } from '@pancakeswap/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { multiplyPriceByAmount } from 'utils/prices'
import { useDGSBusdPrice } from 'hooks/useBUSDPrice'

import tokens from 'config/constants/tokens'
import { useTokenAirdropContractState } from 'state/deblox/igo/hooks'
import { fetchTokenAirdropContract } from 'state/deblox/igo/reducer'
import { getAirdropContractAddress, getTokenAirdropContractAddress } from 'utils/addressHelpers'
import AirdropModal from './components/AirdropModal'
import { footerLinks } from './config/footerConfig'

import config from './config/config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'


const ClaimWrap = styled(Box)`
  font-size: 16px;
  font-weight: normal;
  margin: 0 24px;
  span {
    margin: 0px 16px 8px 0;
    display: inline-block;
    vertical-align: middle;
    ${({ theme }) => theme.mediaQueries.lg} {
      margin: 0 16px 0 0;
    }
  }
  img {
    height: 23px;
    vertical-align: middle;
    padding: 3px 0;
    margin-top: -3px;
    margin-left: -3px;
  }
  .pancake-button--disabled {
    img {
      opacity: .4;
    }
  }

  .btn {
    cursor: pointer;
    &:hover {
      color: red;
    }
  `

const swapHref = `https://pancakeswap.finance/swap?outputCurrency=${tokens.dgs.address}`
const Menu = props => {
  const { isDark, toggleTheme } = useTheme()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()

  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const userRoutes = ['/home', '/avatar', '/game', '/farm', '/community']
  const cakeIcon = <img src="/images/farm/dgs_logo.png" alt="" style={{width: 30, marginRight: 4}} />
  
  const stakingTokenPrice = multiplyPriceByAmount(useDGSBusdPrice(), 1)
  const allowAirdrop = process.env.REACT_APP_ALLOW_AIRDROP
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const airdropAddress = getTokenAirdropContractAddress()
  const airdropContractInfo = useTokenAirdropContractState()

  const [onPresentClaimModal, onDismiss] = useModal(<AirdropModal onDismiss/>)
  const { isMobile } = useMatchBreakpoints()

  const AirdropBtn = () => {

    // console.log('AllowAirdrop', allowAirdrop, airdropContract.hasClaimed, airdropContract.isWhitelisted)
    return (
      allowAirdrop && (
        <ClaimWrap>
          <div>
            { isMobile 
              ?
              <Box className="btn" onClick={onPresentClaimModal}>
                <img src="/images/avatar/gift.png" alt="" style={{marginLeft: 8}} />
              </Box>
              :
              <Button scale="sm" onClick={onPresentClaimModal}>
                {t('Airdrop')}
                <img src="/images/avatar/gift.png" alt="" style={{marginLeft: 8}} />
              </Button>
            }
              
            </div>
        </ClaimWrap>
      )
    )
  }

  useEffect(() => {
    const updateTokenAirdropInfo = () => {
      dispatch(fetchTokenAirdropContract({ account, airdropAddress }))
    }

    if (allowAirdrop && account) {
      updateTokenAirdropInfo()
    } 
  }, [allowAirdrop, account, airdropAddress, dispatch])


  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      
      links={config(t)}
      subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={footerLinks(t)}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyCakeLabel={t('Buy DGS')}

      // hasGlobalMenu={userRoutes.includes(pathname)}
      HasUserMenu={userRoutes.includes(pathname.split('/').slice(0, 2).join('/'))}
      hasCakePrice
      cakePriceUsd={stakingTokenPrice}
      cakeHref={swapHref}
      cakeIconFC={cakeIcon}
      customComponent={AirdropBtn()}
      {...props}
    />
  )
}

export default Menu
