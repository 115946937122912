import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Quantico';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Quantico.ttf);
    src: url(/fonts/Quantico.woff);
    src: url(/fonts/Quantico.woff2);
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Poppins.ttf);
    src: url(/fonts/Poppins.woff);
    src: url(/fonts/Poppins.woff2);
  }

  * {
    font-family: Poppins, Kanit, sans-serif;
  }
  body {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.background};
    &.overflow-hidden {
      overflow: hidden;
    }
    img {
      height: auto;
      max-width: 100%;
      border: 0;
      outline: 0;
    }
    .fullscreen-box {
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      width: 100%;
      height: calc(100vh - 106px) !important;
      overflow: auto !important;
      margin: 0;
      outline: none;
      z-index: 99;
      background: blue;
      ${({ theme }) => theme.mediaQueries.lg} {
        height: calc(100vh - 56px) !important;
      }
    }
    #portal-root {
      [data-popper-placement="top-start"], [data-popper-placement="bottom-start"] {
        margin-left: -11px;
      }
      [data-popper-placement="top-end"], [data-popper-placement="bottom-end"] {
        margin-left: 11px;
      }
    }
  }
`

export default GlobalStyle
