import multicall from 'utils/multicall'
import { BigNumber } from 'bignumber.js'
import gameSlot from 'config/abi/deblox/slot.json'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { SlotContractState } from './types'

export const fetchGameSlotContract = async (
  contractAddress: string
): Promise<SlotContractState> => {

  // console.log('----fetch game slot', contractAddress)
  const call = [
    {
      address: contractAddress,
      name: 'isInitialized'
    },
    {
      address: contractAddress,
      name: 'isSuspended'
    },
    {
      address: contractAddress,
      name: 'admin'
    },
    {
      address: contractAddress,
      name: 'treasury'
    },
    {
      address: contractAddress,
      name: 'ownerAccount'
    },
    {
      address: contractAddress,
      name: 'NFT'
    },
    {
      address: contractAddress,
      name: 'tokenId'
    },
    {
      address: contractAddress,
      name: 'performanceFee'
    },
    {
      address: contractAddress,
      name: 'balance'
    },
    {
      address: contractAddress,
      name: 'balanceOfPoints'
    }
    // ,
    // {
    //   address: contractAddress,
    //   name: 'unlockLimit'
    // }
  ]

  const [isInitialized, 
    isSuspended, 
    currentAdmin,
    treasury, 
    ownerAccount, 
    nft, 
    tokenId,
    performanceFee,
    balance, 
    balanceOfPoints,
    unlockLimit
  ] = await multicall(gameSlot, call)

  // console.log('----multi', isInitialized, currentAdmin, contractAddress, ownerAccount, tokenId, balance)
  return { 
    address: contractAddress,
    initialized: isInitialized[0],
    suspended: isSuspended[0],
    currentAdmin: currentAdmin[0],
    treasury: treasury[0],
    ownerAccount: ownerAccount[0],
    nft: nft[0],
    tokenId: parseInt(tokenId[0].toString()),
    performanceFee: parseInt(performanceFee.toString()) / 100,
    balance: getFullDisplayBalance(balance, 18, 3),
    balanceOfPoints: parseInt(balanceOfPoints[0].toString()),
    unlockLimit: 0 // parseInt(unlockLimit[0].toString())
  }
}