import React from 'react'
import { HelpIcon, useTooltip, Box, BoxProps, Placement } from '@pancakeswap/uikit'
import styled from 'styled-components'

interface Props extends BoxProps {
  text: string | React.ReactNode
  placement?: Placement
}

const Wrap = styled.div`
  display: inline-block;
  cursor: pointer;
`
const QuestionWrapper = styled.div`
  display: inline-block;
  :hover,
  :focus {
    opacity: 0.9;
  }
`
const image = (<img src="/images/question.png" alt="" style={{width: 20, height: 20}} />)
const QuestionHelper: React.FC<Props> = ({ text, placement = 'right-end', children = image, ...props }) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, { placement, trigger: 'hover' })

  return (
    <Wrap {...props}>
      {tooltipVisible && tooltip}
      <QuestionWrapper ref={targetRef}>
        { children }
      </QuestionWrapper>
    </Wrap>
  )
}

export default QuestionHelper
