import multicall from 'utils/multicall'
import { BigNumber } from 'bignumber.js'
import gameSlot from 'config/abi/deblox/slot.json'
import { BIG_ZERO } from 'utils/bigNumber'
import debloxNftAbi from 'config/abi/deblox/debloxNFTAbi.json'
import airdropContractAbi from 'config/abi/deblox/airdropContractAbi.json'
import tokenAirdropContractAbi from 'config/abi/deblox/tokenAirdropContractAbi.json'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { AirdropContractState, AvatarRarity, ContractState, TokenAirdropContractState, TokenIdWithCollectionAddress } from './types'

export const fetchWalletTokenIdsForCollection = async (
  account: string,
  collectionAddress: string
): Promise<TokenIdWithCollectionAddress> => {

  const call = [
    {
      address: collectionAddress,
      name: 'balanceOf',
      params: [account]
    },
    {
      address: collectionAddress,
      name: 'onlyWhitelisted',
    },
    {
      address: collectionAddress,
      name: 'isWhitelisted',
      params: [account]
    },
    {
      address: collectionAddress,
      name: 'walletOfOwner',
      params: [account]
    }
  ]

  const [balanceOfResponse, onlyWhitelisted, isWhiteListed, walletOfOwner] = await multicall(debloxNftAbi, call)

  // console.log('----result of owner', collectionAddress, account, balanceOfResponse, onlyWhitelisted, isWhiteListed, walletOfOwner[0])

  const result = onlyWhitelisted[0] ? isWhiteListed[0] : true
  // console.log('----isWhitelisted', onlyWhitelisted[0], isWhiteListed[0], result)
  // User has no NFTs for this collection
  if (new BigNumber(balanceOfResponse).eq(BIG_ZERO)) {
    return { tokenIds: [], collectionAddress, isWhitelisted: result }
  }

  const tokenIds: string[] = []
  const list = walletOfOwner[0]
  for (let i = 0; i < list.length; i++) {
    tokenIds.push(list[i].toString())
  }

  // console.log('----result of owner', tokenIds, tokenIds.length, walletOfOwner.length)
  return { tokenIds, collectionAddress, isWhitelisted: result }
}

export const getInfoPerNftContract = async (
  collectionAddress: string,
): Promise<ContractState> => {
  
  const call = [
    {
      address: collectionAddress,
      name: 'saleLive',
    },
    {
      address: collectionAddress,
      name: 'nftPerAddressLimit',
    },
    {
      address: collectionAddress,
      name: 'price',
    },
    {
      address: collectionAddress,
      name: 'currentId',
    },
    {
      address: collectionAddress,
      name: 'maxSupply',
    },
    {
      address: collectionAddress,
      name: 'onlyWhitelisted',
    },
    {
      address: collectionAddress,
      name: 'maxMintAmount',
    }
  ]

  const [saleLive, nftPerAddressLimit, price, currentId, maxSupply, onlyWhitelisted, maxMintAmount] = await multicall(debloxNftAbi, call)
  // const quota = (nftPerAddressLimit >= balanceOf) 
  //   ? 
  //   new BigNumber(nftPerAddressLimit).minus(new BigNumber(balanceOf)).toNumber()
  //   :
  //   0

  // console.log('----format', parseInt(currentId.toString()), nftPerAddressLimit.toString(), price.toString(), typeof currentId, new BigNumber(currentId).isGreaterThanOrEqualTo(new BigNumber(maxSupply)))
  return {
    saleLive: saleLive[0], 
    addressLimit: parseInt(nftPerAddressLimit.toString()),
    price: getFullDisplayBalance(price, 18, 3),
    soldout: new BigNumber(currentId).isGreaterThanOrEqualTo(new BigNumber(maxSupply)),
    currentId: parseInt(currentId.toString()),
    maxSupply: parseInt(maxSupply.toString()),
    onlyWhitelisted: onlyWhitelisted[0],
    maxMintAmount: parseInt(maxMintAmount.toString())
  }
}


export const getAirdropContract = async (
  tokenIds: number[],
  collectionAddress: string,
): Promise<AirdropContractState> => {
  
  const call = [
    {
      address: collectionAddress,
      name: 'claimable',
    },
    {
      address: collectionAddress,
      name: 'balance',
    },
    {
      address: collectionAddress,
      name: 'claimableTokenIds',
      params: [tokenIds]
    }
  ]

  // console.log('------collectionAddress', tokenIds, collectionAddress)
  const [claimable, balance, ids] = await multicall(airdropContractAbi, call)
  // console.log('----hasClaimed', balance, ids)

  const list = []
  for (let i = 0; i < ids[0].length; i++) {
    const id = parseInt(ids[0][i].toString())
    if (id > 0) {
      list.push(id)
    }
  }

  return {
    claimable: claimable[0],
    balance: parseInt(balance[0].toString()),
    claimableTokenIds: list
  }
}

export const getTokenAirdropContract = async (
  account: string,
  collectionAddress: string,
): Promise<TokenAirdropContractState> => {
  
  const call = [
    {
      address: collectionAddress,
      name: 'claimable',
    },
    {
      address: collectionAddress,
      name: 'balance',
    },
    {
      address: collectionAddress,
      name: 'hasClaimed',
      params: [account]
    },
    {
      address: collectionAddress,
      name: 'whitelistAddress',
      params: [account]
    },
    {
      address: collectionAddress,
      name: 'premiumAddress',
      params: [account]
    },
    {
      address: collectionAddress,
      name: 'tokensPerClaim'
    },
    {
      address: collectionAddress,
      name: 'tokensPerClaimPremium'
    }
  ]

  // console.log('------collectionAddress', collectionAddress)
  const [claimable, balance, hasClaimed, isWhitelisted, isPremium, tokensPerClaim, tokensPerClaimPremium] = await multicall(tokenAirdropContractAbi, call)
  // console.log('----hasClaimed', hasClaimed, balance, isWhitelisted, isPremium, tokensPerClaim, tokensPerClaimPremium)

  return {
    claimable: claimable[0],
    balance: getFullDisplayBalance(balance, 18, 0),
    hasClaimed: hasClaimed[0],
    isWhitelisted: isWhitelisted[0],
    isPremium: isPremium[0],
    claimableAmount: getFullDisplayBalance(tokensPerClaim, 18, 0),
    claimablePremiumAmount: getFullDisplayBalance(tokensPerClaimPremium, 18, 0),
  }
}
  
const publicUrl = process.env.REACT_APP_PUBLIC_API
const staticUrl = process.env.REACT_APP_STATIC_API
export const fetchNonce = async (account, tokenId) => {
  const res = await fetch(`${publicUrl}/unsignedMessage/${account}/${tokenId}`)
  if (res.ok) {
    const json = await res.json()
    // console.log('----response', json)
    return json.data
  }
  console.error(`API: Failed to fetch Nonce ${account}`, res.status)
  return null
}

export const fetchOwner = async (
  collectionAddress: string,
  tokenId: number
  ): Promise<string> => {
  const call = [
    {
      address: collectionAddress,
      name: 'ownerOf',
      params: [tokenId]
    },
  ]

  const [owner] = await multicall(debloxNftAbi, call)

  return owner ? owner[0] : ''
}

export const fetchRarity = async (): Promise<any> => {
  const json = await fetch(`${staticUrl}/avatar/avatar_rarity.json`)
    .then(res => res.json())
    .then((res: AvatarRarity[]) => {
      // console.log('----fetch', res)
        return res
    })
    .catch((e) => {
      console.error('Failed to fetch rarity json', e)
      return null
    });

    return json
  // if (res.ok) {
  //   const json = await res.json()
  //   // console.log('----response', json)
  //   return json
  // }
  // return []
}