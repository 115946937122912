import { useWeb3React } from '@web3-react/core'
import useRefresh from 'hooks/useRefresh'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { State } from 'state/types'
import { getAvatarNFTAddress, getProviderNFTAddress } from 'utils/addressHelpers'
import { fetchRarity } from './helpers'
import { fetchAvatarNfts, fetchProviderContract } from './reducer'
import { AvatarRarity } from './types'

export const useAvatarNFTCollections = () => {
  return useSelector((state: State) => state.igo.data.avatar)
}

export const useUserAvatarData = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const collectionAddress = getAvatarNFTAddress()
  
  useEffect(() => {
    const updataAvatarList = () => {
      // console.log('---balance')
      dispatch(fetchAvatarNfts({account, collectionAddress}))
    }

    if (account) {
      updataAvatarList()
    } 
  }, [account, collectionAddress, dispatch, slowRefresh])
}

export const usePollProviderNFTCollections = () => {
  const dispatch = useAppDispatch()
  // const { slowRefresh } = useRefresh()
  const collectionAddress = getProviderNFTAddress()
  
  useEffect(() => {
    const updateAccountInfo = async () => {
      // console.log('----load game contract')
      dispatch(fetchProviderContract({collectionAddress}))
    }

    updateAccountInfo()
  }, [collectionAddress, dispatch])
}

export const useProviderNFTCollections = () => {
  return useSelector((state: State) => state.igo.data.provider)
}
export const useAvatarContract = () => {
  return useSelector((state: State) => state.igo.data.avatarContract)
}
export const useOwnerId = () => {
  return useSelector((state: State) => state.igo.data.ownerId)
}
export const useProviderContrct = () => {
  return useSelector((state: State) => state.igo.data.providerContract)
}
export const useProviderContrctState = () => {
  return useSelector((state: State) => state.igo)
}

export const useGetNFTInitializationState = () => {
  return useSelector((state: State) => state.igo.initializationState)
}
export const useIGODataState = () => {
  return useSelector((state: State) => state.igo)
}
export const useRarityList = () => {
  return useSelector((state: State) => state.igo.avatarRarity)
}

export const useAirdropContractState = () => {
  return useSelector((state: State) => state.igo.claimsRecords)
}

export const useTokenAirdropContractState = () => {
  return useSelector((state: State) => state.igo.tokenClaimsRecords)
}