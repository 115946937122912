
import useRefresh from 'hooks/useRefresh'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { State } from 'state/types'
import { fetchGameSlotDetails } from './reducer'

export const usePollGameSlotData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
      dispatch(fetchGameSlotDetails())
  }, [dispatch, slowRefresh])
}

export const useGameSlots = () => {
  return useSelector((state: State) => state.slots.data)
}